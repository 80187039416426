<template>
  <el-dialog title="查看流水--待定" :visible.sync="dialogVisible" width="80%" :before-close="handleClose">
    <el-table :data="BorrowLogFrom" border stripe style="width: 100%" show-summary>
      <el-table-column label="支行名称" prop="subbranch" />
      <el-table-column label="借走人别名" prop="borrowAlias" />
      <el-table-column label="借走日期" prop="borrowData" />
      <el-table-column label="借走备注" prop="borrowRemark" />
      <el-table-column label="预计归还时间" prop="preReturnData" />
      <el-table-column label="借走资金" prop="borrowMoney" />
      <el-table-column label="归还金额" prop="returnMoney" />
      <el-table-column label="差异金额" prop="diffeMoney" />
      <el-table-column label="借走备注" prop="borrowRemark" />
    </el-table>
  </el-dialog>
</template>
<script>
import { getNoRetuenById,getCancelMoney } from "@/api/capital/bank/bankBorrow";
export default {
  name: "noBorrowLog",
  data() {
    return {
      dialogVisible: false,
      BorrowLogFrom: []
    };
  },
  methods: {
    //打开弹窗
    show(id) {
      this.dialogVisible = true;
      this.getCancellByIdButton(id);
    },
    getCancellByIdButton(id) {
      getCancelMoney(id).then(res => {
        console.log(res);
        this.BorrowLogFrom = res.result;
      });
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then(_ => {
          done();
        })
        .catch(_ => {});
    }
  }
};
</script>